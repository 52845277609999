import React from "react";
import "./menutitle.css";


export default function MenuTitle(props) {

    return (
            <h1 className="menuTitle">{props.text}</h1>
    )
}

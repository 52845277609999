import React, {useState, useEffect, useContext} from 'react';
import Baked from "../../images/baked.png"
import "./dessert.css"
import MenuText from '../../components/menutext/MenuText';
import MenuLine1 from '../../components/menuline1/MenuLine1';
import MenuLine2 from '../../components/menuline2/MenuLine2';
import MenuImages from '../../components/menuimages/MenuImages';
import MenuTitle from '../../components/menutitle/menuTitle';


export default function Dessert() {

    return (
        <>

        <div className="header2">
            <a href="https://jamiewcgraham.github.io/scrumptious/#/"><button className="primary">HOME</button></a>
        </div>
    
        <div className="footer2 footer3">
            {/* <p className="footerText">Hello</p> */}
        </div>
      
        <div className="backgroundWaves menuBlock">
        
         <div className="menuStanza">
             <div className="responsiveStanza">

            <MenuTitle text="Dessert"></MenuTitle>
             <br/>

             <MenuLine2 text="Waffle with Fruit & Ice Cream" dollars="4" cents="99"/>

             <MenuLine2 text="Banana Split" dollars="4" cents="99"/>


        </div>
        </div>

            <MenuImages 
            link1="https://s3-media0.fl.yelpcdn.com/bphoto/UBM8GxM-zZPto8pVlvofKw/o.jpg"
            link2="https://i.ibb.co/1qtHgjM/Screen-Shot-2021-09-15-at-2-53-42-PM.png"
            caption1="Waffles with Fruit & Ice Cream"
            caption2="Banana Split"
            />

        </div>

        <div className="footer2">
            {/* <p className="footerText">Hello</p> */}
        </div>

        </>

    )
}

import React, {useState, useEffect, useContext} from 'react';
import Baked from "../../images/baked.png"
import "./lunchndinner.css"
import MenuText from '../../components/menutext/MenuText';
import MenuLine1 from '../../components/menuline1/MenuLine1';
import MenuLine2 from '../../components/menuline2/MenuLine2';
import MenuImages from '../../components/menuimages/MenuImages';
import MenuTitle from '../../components/menutitle/menuTitle';


export default function LunchNDinner() {

    return (
        <>
    
       <div className="header2">
            <a href="https://jamiewcgraham.github.io/scrumptious/#/"><button className="primary">HOME</button></a>
        </div>
    
        <div className="footer2 footer3">
            {/* <p className="footerText">Hello</p> */}
        </div>
      
      
        <div className="backgroundWaves menuBlock">
        
         <div className="menuStanza">
             <div className="responsiveStanza">
            <MenuTitle text="Sandwiches"></MenuTitle>
             <br/>
             <MenuText text="Served with home fries or French fries" />
             <MenuText text="on white, whole wheat or rye bread" />
             <MenuLine1 text="Substitute sweet potato fries or side salad" dollars="2" cents="99" />
             <MenuLine1 text="Substitute side fruit" dollars="4" cents="99" />
             <div className="bottomIndent"></div>

             <MenuLine2 text="Fried Egg & Bacon Sandwich" dollars="6" cents="49" />
 
             <MenuLine2 text="BLT Sandwich" dollars="6" cents="99"/>
             <MenuText  text="Crispy bacon, lettuce and tomatoes" /> 

             <MenuLine2 text="Western Sandwich" dollars="6" cents="99"/>
             <MenuText  text="Eggs, ham, onions & green peppers" /> 

             <MenuLine2 text="Grilled Triple Cheese Sandwich" dollars="6" cents="99"/>
             <MenuText  text="Swiss, Provolone & Cheddar" /> 

             <MenuLine2 text="Meat Lovers' Sandwich" dollars="10" cents="49"/>
             <MenuText  text="Eggs, beef, chicken, bacon, onions," /> 
             <MenuText  text="green peppers & cheddar. Served on rye bread." /> 

             <MenuLine2 text="Montreal Smoked Meat Sandwich" dollars="10" cents="49"/>
             <MenuText  text="Served on rye bread" /> 

             <MenuLine2 text="Peameal Bacon & Swiss Sandwich" dollars="7" cents="99"/>
             <MenuText  text="Served on ciabatta bun" /> 

             <MenuLine2 text="Club House Sandwich" dollars="10" cents="99"/>
             <MenuText  text="Chicken, bacon, tomatoes, lettuce & mayonnaise" /> 

             <MenuLine2 text="Fresh Chicken Ciabatta Bun" dollars="10" cents="99"/>
             <MenuText  text="Chicken breast, onions, chipotle sauce" /> 
             <MenuText  text="& cheddar. Served on a ciabatta bun" />

             <MenuLine2 text="Scrumptious Ciabatta Bun" dollars="9" cents="49"/>
             <MenuText  text="Peameal bacon, lettuce & tomatoes" /> 
             <MenuText  text="with chipotle sauce & Provolone on ciabatta bun" />




        </div>
        </div>

            <MenuImages 
            link1="https://s3-media0.fl.yelpcdn.com/bphoto/JuR4YR8kqM8Qy-WMLqUZwg/o.jpg"
            link2="https://s3-media0.fl.yelpcdn.com/bphoto/CPOyDRQIaRmXNnSvIryk8g/o.jpg"
            caption1="Scrumptious Ciabatta Bun"
            caption2="Club House Sandwich"
            />

        </div>

        <div className="footer2">
            {/* <p className="footerText">Hello</p> */}
        </div>


        <div className="backgroundWaves menuBlock">
         <div className="menuStanza">
         <div className="responsiveStanza">


         <MenuTitle text="Wraps"></MenuTitle>
             <br/>
             
             <MenuText text="Served with your choice of home fries or French fries" />
             <MenuText text="Wrapped in a warm tortilla" />

             <br/>

             <MenuLine2 text="Western Wrap" dollars="10" cents="99" />
             <MenuText text="Eggs, ham, green peppers, onions & cheddar"/>

             <MenuLine2 text="Mediterranean Wrap" dollars="10" cents="49" />
             <MenuText text="Eggs, chicken, green peppers,"/>
             <MenuText text="black olives, onioins, tomatoes & Feta"/>


             <MenuLine2 text="Chicken Caesar Wrap" dollars="9" cents="99" />
             <MenuText text="Fresh chicken breast, crispy bacon,"/>
             <MenuText text="lettuce, Caesar dressing & Parmesean"/>


             <br/>


            <MenuTitle text="Lighter Choices"></MenuTitle>
             <br/>
             <MenuLine2 text="Fruit Bowl" dollars="6" cents="99" />
             <MenuLine2 text="Garden Salad" dollars="6" cents="99" />
             <MenuLine2 text="Caesar Salad" dollars="6" cents="99" />

             <MenuLine2 text="Smoked Salmon Salad" dollars="12" cents="49" />
             <MenuText text="Smoked salmon, Provolone"/>
             <MenuText text="& salad with balsamic vinaigrette"/>

             <MenuLine2 text="Grilled Chicken Salad" dollars="11" cents="99" />
             <MenuLine2 text="Bagel & Cream Cheese" dollars="3" cents="49" />
             <MenuLine2 text="Bagel with Smoked Salmon & Cream Cheese" dollars="9" cents="99" />



        </div>
        </div>

            <MenuImages 
            link1="https://s3-media0.fl.yelpcdn.com/bphoto/Q0LJ6RRPxUtGaa2X5Yir3w/o.jpg"
            link2="https://i.ibb.co/9ZCjbkh/Screen-Shot-2021-09-15-at-2-02-47-PM.png"
            caption1="Chicken Caesar Wrap"
            caption2="Garden Salad"
            />

        </div>


        <div className="footer2">
            {/* <p className="footerText">Hello</p> */}
        </div>


        <div className="backgroundWaves menuBlock">
         <div className="menuStanza">
         <div className="responsiveStanza">
   

            <MenuTitle text="Scrumptious Meals" />
            <div className="topIndent" />
            <div className="topIndent" />
            <MenuText text="Served with two sides:" />
            <MenuText text="salad, toast, home fries, or French fries" />
            <MenuLine2 text="Fresh Chicken Breast" dollars="11" cents="99" />
            <MenuLine2 text="NY Steak 6oz" dollars="16" cents="99" />
            <MenuLine2 text="Fresh Atlantic Salmon" dollars="14" cents="49" />

            <MenuLine2 text="Sweet & Sour Chicken" dollars="12" cents="99" />
            <MenuText text="Diced potatoes cooked with eggs, bacon & onions"/>

            <MenuLine2 text="Scumptious Rice Omelette" dollars="12" cents="99" />
            <MenuText text="Fried rice cooked with chicken, onions"/>
            <MenuText text="and ketchup wrapped in an omelette"/>

            <MenuLine2 text="Meat Lover's Rice Omelette" dollars="13" cents="99" />
            <MenuText text="Fried rice cooked with chicken, eggs"/>
            <MenuText text="beef, bacon, onions, green peppers & cheddar"/>


            <br/>

            <MenuTitle text="Poutines" />
            <div className="topIndent" />

            <MenuLine2 text="Poutine" dollars="9" cents="99" />

            <MenuLine2 text="Bacon & Sausage Poutine" dollars="9" cents="99" />
            <MenuText text="Fries topped with chopped bacon,"/>
            <MenuText text="sausage, cheese curds & gravy"/>

            <MenuLine2 text="Meat Lover's Poutine" dollars="11" cents="99" />
            <MenuText text="Fries with beef, chicken & bacon."/>
            <MenuText text="Topped with cheese curds & gravy"/>

            <MenuLine2 text="Bacon & Hash Poutine" dollars="10" cents="99" />
            <MenuText text="Diced potatoes cooked with crispy bacon,"/>
            <MenuText text="onions, topped with cheese curds & gravy"/>

            <MenuLine2 text="Fried Chicken Poutine" dollars="10" cents="99" />
            <MenuText text="Fries with fried chicken, topped"/>
            <MenuText text="with cheese curds & gravy"/>

        </div>
        </div>

            <MenuImages 
            link1="https://s3-media0.fl.yelpcdn.com/bphoto/eMTsw0HpNA4uBqkMWeG3Aw/o.jpg"
            link2="https://cdn.tasteatlas.com/images/recipes/88753ad52d2443468569929cf7ba6468.jpg"
            caption1="Scrumptious Rice Omelette"
            caption2="Poutine"
            />

        </div>
        



        <div className="footer2">
            {/* <p className="footerText">Hello</p> */}
        </div>


        <div className="backgroundWaves menuBlock">

        <div className="menuStanza">
         <div className="responsiveStanza">
   

            <MenuTitle text="Burgers" />
            <br/>

            

            <MenuText text="Served with home fries or French fries." />
             <MenuLine1 text="Substitute sweet potato fries or side salad" dollars="2" cents="99" />
             <MenuLine1 text="Substitute fruit bowl" dollars="4" cents="99" />

             <MenuLine2 text="Classic Burger" dollars="8" cents="99" />
             <MenuText text="Pickles, tomatoes, lettuce & cheddar (onions optional)"/>

             <MenuLine2 text="Breakfast Burger" dollars="9" cents="99" />
             <MenuText text="With egg, bacon, and cheddar cheese."/>

             <MenuLine2 text="Montreal Smoked Meat Burger" dollars="12" cents="49" />
             <MenuText text="Montreal smoked meat, chipotle sauce & Swiss"/>

             <MenuLine2 text="Scrumptious Burger" dollars="12" cents="99" />
             <MenuText text="Double patties, crispy bacon, egg,"/>
             <MenuText text="chipotle & cheddar"/>
             
             <MenuLine2 text="Meat Lover's Burger" dollars="13" cents="99" />
             <MenuText text="Peameal, Crispy bacon, tomatoes,"/>
             <MenuText text="lettuce & cheddar"/>

             <MenuLine2 text="Mushrooms & Provolone Burger" dollars="11" cents="49" />
             <MenuText text="Mushrooms, chipotle sauve & Provolone"/>
   
            </div>
            </div>


            <MenuImages 
            link1="https://s3-media0.fl.yelpcdn.com/bphoto/yhVIR7Nc9hK993WE2sJDKg/o.jpg"
            link2="https://assets.change.org/photos/1/yf/fw/edyFfWZcVmVPXRS-800x450-noPad.jpg?1524165426"
            caption1="Breakfast Burger"
            caption2="Classic Burger"
            />

        </div>



        <div className="footer2">
            {/* <p className="footerText">Hello</p> */}
        </div>


        <div className="backgroundWaves menuBlock">
                <div className="menuStanza">
                <div className="responsiveStanza">
            

                        <MenuTitle text="Side Order" />
                        <div className="topIndent" />
                        <MenuLine2 text="Side Toast" dollars="2" cents="99"  />
                        <MenuLine2 text="Home Fries or French Fries" dollars="2" cents="99"  />
                        <MenuLine2 text="Sweet Potato Fries" dollars="2" cents="99"  />
                        <MenuLine2 text="Buttermilk Pancake (1 piece)" dollars="2" cents="99"  />
                        <MenuLine2 text="French Toast (1 piece)" dollars="2" cents="99"  />
                        <MenuLine2 text="Ham, Bacon, or Sausage (4 pieces)" dollars="3" cents="99"  />
                        <MenuLine2 text="Peameal Bacon (3 piece)" dollars="4" cents="99"  />
                        <MenuLine2 text="Hollandaise Sauce" dollars="2" cents="99"  />
                        <MenuLine2 text="Gravy" dollars="2" cents="49"  />
                        <MenuLine2 text="Sliced Lemon" dollars="2" cents="99"  />
                        <MenuLine2 text="Sliced Tomato" dollars="2" cents="99"  />
                        <div/>
                    </div>
                    </div>

                    <MenuImages 
                    link1="https://i.ibb.co/Pz21VBv/Screen-Shot-2021-09-14-at-4-31-17-PM.png"
                    link2="https://i.ibb.co/GkKjCS1/Screen-Shot-2021-09-14-at-4-32-28-PM.png"
                    caption1="Fries"
                    caption2="Sausages"
                    />
        </div>



        </>

    )
}

import React, {useState, useEffect, useContext} from 'react';
import Baked from "../../images/baked.png"
import "./drinks.css"
import MenuText from '../../components/menutext/MenuText';
import MenuLine1 from '../../components/menuline1/MenuLine1';
import MenuLine2 from '../../components/menuline2/MenuLine2';
import MenuImages from '../../components/menuimages/MenuImages';
import MenuTitle from '../../components/menutitle/menuTitle';


export default function Drinks() {

    return (
        <>
    
        <div className="header2">
            <a href="https://jamiewcgraham.github.io/scrumptious/#/"><button className="primary">HOME</button></a>
        </div>
    
        <div className="footer2 footer3">
            {/* <p className="footerText">Hello</p> */}
        </div>
      
      
        <div className="backgroundWaves menuBlock">
        
         <div className="menuStanza">
             <div className="responsiveStanza">
            <MenuTitle text="Drinks"></MenuTitle>
             <br/>
             <MenuLine2 text="Strawberry Smoothie" dollars="4" cents="99"/>

             <MenuLine2 text="Mango Smoothie" dollars="4" cents="99"/>

             <MenuLine2 text="Banana Smoothie" dollars="4" cents="99"/>

             <MenuLine2 text="Strawberry & Banana Smoothie" dollars="4" cents="99"/>

             <MenuLine2 text="Freshly Squezed Orange Juice" dollars="5" cents="99"/>

             <MenuLine2 text="Milk" dollars="2" cents="49"/>

             <MenuLine2 text="Juice" dollars="2" cents="25"/>

             <MenuLine2 text="Coffee" dollars="2" cents="25"/>

             <MenuLine2 text="Hot Tea" dollars="2" cents="25"/>

             <MenuLine2 text="Herbal Tea" dollars="2" cents="55"/>

             <MenuLine2 text="Hot Chocolate" dollars="2" cents="49"/>

             <MenuLine2 text="Creamy Hot Chocolate" dollars="3" cents="49"/>

             <MenuLine2 text="Soft Drinks" dollars="2" cents="49"/>

             <MenuLine2 text="Bottled Water" dollars="1" cents="75"/>

             <MenuLine2 text="Perrier" dollars="2" cents="25"/>


        </div>
        </div>

            <MenuImages 
            link1="https://www.avalanchepizza.com/wp-content/uploads/2021/05/intro-1587655828.jpg"
            link2="https://images-gmi-pmc.edge-generalmills.com/9b012bef-3a3a-4ba9-a930-821877eb4dc6.jpg"
            caption1="Freshly Squezed Orange Juice"
            caption2="Strawberry Smoothie"
            />

        </div>

        <div className="footer2">
            {/* <p className="footerText">Hello</p> */}
        </div>

        </>

    )
}
